import React, { useEffect } from 'react';
import {
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  ListSubheader,
} from '@mui/material';

import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';

import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
// import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
// import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import AppSidebarLayout from '../shared/AppSidebarLayout';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

function Admin() {
  const location = useLocation();
  const navigate = useNavigate();

  const app = location.pathname.split('/').at(-1);
  useEffect(() => {
    if (app === 'admin' || app === 'quartermaster') {
      navigate('/admin/manifests');
    }
  }, [app, navigate]);

  const NavMenuContent = (
    <List
      component='nav'
      subheader={
        <ListSubheader component='div' id='nested-list-subheader'>
          Quartermaster
        </ListSubheader>
      }
    >
      <ListItemButton
        selected={app === 'manifests'}
        onClick={() => {
          navigate('/admin/manifests');
        }}
      >
        <ListItemIcon>
          <GradingOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary='Manifests' />
      </ListItemButton>
    </List>
  );

  return (
    <AppSidebarLayout
      headerTitle={'Administration'}
      HeaderIcon={FactCheckOutlinedIcon}
      SidebarContent={NavMenuContent}
    >
      <Outlet />
    </AppSidebarLayout>
  );
}

export default Admin;
