import React from 'react';

import {
  Box,
  FormControlLabel,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';

function IncludeHistoryField({ disabled, value, setValue }) {
  const handleChange = (event) => {
    setValue(event.target.checked);
  };

  return (
    <Box>
      <Tooltip
        title={
          <Typography variant='body2'>
            Disable chat history to ignore previous messages in the next
            response
          </Typography>
        }
      >
        <FormControlLabel
          control={
            <Switch
              checked={value}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label='Include Chat History'
          disabled={disabled}
        />
      </Tooltip>
    </Box>
  );
}

export default IncludeHistoryField;
