import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function ManifestCheckPromptField({
  value,
  error,
  setValue,
  setError,
  setChanged,
  edit,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setValue(newValue);
    if (newValue === '') {
      setError('Check prompt cannot be blank');
    } else {
      setError(false);
    }
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <TextField
          label='Prompt'
          id='prompt'
          aria-describedby='prompt-helper-text'
          variant='outlined'
          onChange={handleChange}
          error={Boolean(error)}
          value={value}
          disabled={edit}
          InputLabelProps={{ shrink: value?.length > 0 }}
          multiline
          minRows={15}
        />
        {error ? (
          <FormHelperText id='prompt-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='prompt-helper-text'>
            The prompt is the question actually sent to the language model for
            evaluation. Enter a highly specific criteria in the form of a
            question that will verify if the document is compliant with this
            requirement.
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
