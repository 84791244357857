import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  DialogActions,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  FormHelperText,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';

function ChatFeedbackDialog({ line, open, handleClose, updateChatLine }) {
  const [loading, setLoading] = useState(false);
  const [bad_generation_type, setBadGenerationType] = useState(
    line?.bad_generation_type || ''
  );
  const [bad_generation_comment, setBadGenerationComment] = useState(
    line?.bad_generation_comment || ''
  );

  const handleSubmit = async () => {
    setLoading(true);
    await updateChatLine(line.entity_id, {
      bad_generation_type,
      bad_generation_comment,
    });
    setLoading(false);
    handleClose();
  };

  return (
    <Dialog onClose={() => handleClose()} open={open}>
      <DialogTitle>
        <Grid container direction='row' alignItems='center'>
          <Grid item xs>
            Feedback
          </Grid>
          <Grid item xs={1} sx={{ maxWidth: '25px' }}>
            <IconButton aria-label='close' onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <FormControl>
          <FormLabel id='bad-generation-type-group'>What went wrong?</FormLabel>
          <RadioGroup
            aria-labelledby='bad-generation-type-group'
            name='bad_generation_type'
            value={bad_generation_type}
            onChange={(e) => {
              setBadGenerationType(e.target.value);
            }}
            row
          >
            <FormControlLabel
              value='incorrect'
              control={<Radio />}
              label='Incorrect'
            />
            <FormControlLabel
              value='incomplete'
              control={<Radio />}
              label='Incomplete'
            />
            <FormControlLabel
              value='unhelpful'
              control={<Radio />}
              label='Unhelpful'
            />
            <FormControlLabel
              value='unknown'
              control={<Radio />}
              label='Other'
            />
          </RadioGroup>
          <TextField
            label='Tell us more'
            id='bad_generation_comment'
            multiline
            rows={5}
            aria-describedby='bad-generation-comment-helper-text'
            onChange={(e) => setBadGenerationComment(e.target.value)}
            value={bad_generation_comment}
            variant='standard'
          />

          <FormHelperText id='bad-generation-comment-helper-text'>
            Being specific about why the response was poor will help us improve
            future interactions.
          </FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <LoadingButton
            loading={loading}
            variant='contained'
            color='primary'
            onClick={handleSubmit}
          >
            Submit
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default ChatFeedbackDialog;
