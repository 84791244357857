export const MODEL_FAMILIES = {
  LLAMA_2: 'llama_2',
  LLAMA_3: 'llama_3',
  LLAMA_3_1: 'llama_3.1',
  MIXTRAL: 'mixtral',
  IDEFICS: 'idefics',
  IDEFICS2: 'idefics2',
  LLAVA_VICUNA: 'llava_vicuna',
  LLAVA_MISTRAL: 'llava_mistral',
  CODELLAMA: 'codellama',
};
