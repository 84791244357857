import React from 'react';
import { useTheme, Paper, Box, Alert } from '@mui/material';

import ChatLine from './ChatLine';

function ChatBox({ chat_history, generation, updateChatLine }) {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        margin: '10px',
        backgroundColor: theme.palette.grey[100],
        minHeight: '300px',
      }}
    >
      {chat_history.length ? (
        <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
          {chat_history.map((line, i) => (
            <ChatLine
              key={`message_${line.entity_id}`}
              line={line}
              updateChatLine={updateChatLine}
            />
          ))}
          {generation ? (
            <ChatLine
              key={`message_generating`}
              line={{ role: 'assistant', content: generation }}
              updateChatLine={updateChatLine}
            />
          ) : undefined}
        </Box>
      ) : (
        <Box
          sx={{
            height: '100%',
            p: 2,
            borderRadius: '20px 20px 20px 20px',
          }}
        >
          <Alert severity='info'>Ask a question to get started</Alert>
        </Box>
      )}
    </Paper>
  );
}

export default ChatBox;
