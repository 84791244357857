export const getChats = async (api, type) => {
  const resp = await api.get('/chat', { params: { type } });
  return resp.data;
};

export const getChat = async (api, chat_id) => {
  const resp = await api.get(`/chat/${chat_id}`);
  return resp.data;
};

export const createChat = async (api, values) => {
  const resp = await api.post(`/chat/`, values);
  return resp.data;
};

export const updateChat = async (api, chat_id, values) => {
  const resp = await api.put(`/chat/${chat_id}`, values);
  return resp.data;
};

export const deleteChat = async (api, chat_id) => {
  const resp = await api.delete(`/chat/${chat_id}`);
  return resp.data;
};

export const getChatItems = async (api, chat_id) => {
  const resp = await api.get(`/chat/${chat_id}/item`);
  return resp.data;
};

export const addChatItem = async (api, chat_id, values) => {
  const resp = await api.post(`/chat/${chat_id}/item`, values);
  return resp.data;
};

export const updateChatItem = async (api, chat_id, chat_item_id, values) => {
  const resp = await api.patch(`/chat/${chat_id}/item/${chat_item_id}`, values);
  return resp.data;
};

export const generateCompletion = async (
  api,
  chat_id,
  model,
  messages,
  max_tokens,
  temperature,
  top_p
) => {
  const token = localStorage.getItem('accqsure_jwt');

  const init = {
    // signal: AbortSignal.timeout(10000),  // this was causing timeouts with long generations
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      messages,
      model,
      max_tokens,
      temperature,
      top_p,
    }),
  };

  const response = await fetch(
    `${api.defaults.baseURL}/chat/${chat_id}/completion`,
    init
  );

  return response;
};
