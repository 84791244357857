import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Tab, Box, Link, Divider, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Grid from '@mui/material/Unstable_Grid2';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Link as RouterLink } from 'react-router-dom';

import { useUser } from '@/providers/UserContext';
import LoadingMask from '@/components/shared/LoadingMask';
import { useConfiguration } from '@/providers/ConfigurationContext';
import { getDocumentAsset } from '@/actions/documentAsset';
import InspectionDocumentManifestChecks from './InspectionDocumentManifestChecks';

function InspectionDocument({ inspection, document, manifests }) {
  const configuration = useConfiguration();
  const [doc_content, setDocContent] = useState(null);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (document?.document_content_id && !doc_content) {
      getDocumentAsset(
        configuration.api,
        document.document_id,
        document.document_content_id
      )
        .then((data) => {
          setDocContent(data);
        })
        .catch((error) => console.log(error));
    }
  }, [document, doc_content, configuration.api, setDocContent]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  //   if (!inspection_document) {
  //     return <LoadingMask />;
  //   }

  return (
    <Box sx={{ width: '100%' }}>
      <Grid
        container
        component='dl' // mount a Definition List
        spacing={0}
        sx={{ display: 'table' }}
      >
        <Grid>
          <Typography
            component='dt'
            variant='body1'
            sx={{ display: 'table-cell', width: '250px' }}
          >
            <b>Document Name:</b>
          </Typography>
          <Typography
            component='dd'
            variant='body2'
            sx={{ display: 'table-cell' }}
          >
            {document?.document_name}
          </Typography>
        </Grid>
        <Grid>
          <Typography
            component='dt'
            variant='body1'
            sx={{ display: 'table-cell', width: '250px' }}
          >
            <b>Document ID:</b>
          </Typography>
          <Typography
            component='dd'
            variant='body2'
            sx={{ display: 'table-cell' }}
          >
            {document?.document_doc_id}
          </Typography>
        </Grid>
      </Grid>

      <Divider />
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label='Manage Manifests'>
              {(manifests || []).map((manifest, i) => {
                return (
                  <Tab key={manifest.name} label={manifest.name} value={i} />
                );
              })}
            </TabList>
          </Box>

          {(manifests || []).map((manifest, i) => {
            return (
              <TabPanel key={`${i}`} value={i}>
                <InspectionDocumentManifestChecks
                  inspection={inspection}
                  document={document}
                  manifest={manifest}
                />
              </TabPanel>
            );
          })}
        </TabContext>
      </Box>
    </Box>
  );

  //   return (
  //     <Box sx={{ width: '100%' }}>
  //       <Box
  //         sx={{
  //           display: 'flex',
  //           height: '100%',
  //         }}
  //       >
  //         <Box
  //           sx={{
  //             flexGrow: 1,
  //             '& .MuiDataGrid-columnHeader': {
  //               backgroundColor: 'rgba(0, 0, 0, 0.1)',
  //             },
  //           }}
  //         >
  //           <DataGrid
  //             checkboxSelection
  //             columnHeaderHeight={35}
  //             getRowId={(row) => row.entity_id}
  //             columns={columns}
  //             rows={manifests || []}
  //             onSelectionModelChange={handleRowSelection}
  //             onRowSelectionModelChange={handleRowSelection}
  //             rowSelectionModel={rowSelectionModel}
  //             slots={{ toolbar: GridToolbar }}
  //             slotProps={{
  //               toolbar: {
  //                 sx: {
  //                   button: {
  //                     color: 'black',
  //                   },
  //                   svg: {
  //                     color: 'black',
  //                   },
  //                 },
  //                 showQuickFilter: true,
  //               },
  //             }}
  //           />
  //         </Box>
  //       </Box>
  //     </Box>
  //   );
}

export default InspectionDocument;
