import React from 'react';
import { Button, Tooltip, Typography } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const UploadButton = ({ tooltip, name, onChange, disabled, ...rest }) => {
  let input;
  return (
    <>
      <Tooltip
        title={<Typography variant='body2'>{tooltip}</Typography>}
        placement='right'
      >
        <span>
          <Button
            {...rest}
            onClick={(e) => {
              e.preventDefault();
              input.click();
            }}
          >
            <UploadFileIcon />
          </Button>
        </span>
      </Tooltip>
      <input
        ref={(element) => (input = element)}
        hidden
        onChange={(e) => {
          e.preventDefault();
          onChange(e);
        }}
        type='file'
      />
    </>
  );
};
export default UploadButton;
