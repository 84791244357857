import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#ed7205',
      light: '#ed7205',
      dark: '#ed7205',
      contrastText: '#fff',
    },
    secondary: {
      light: '#101428',
      main: '#101428',
      dark: '#101428',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
  },
});

export default theme;
