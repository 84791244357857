import { Container, Typography, Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Outlet } from 'react-router-dom';
import { useUser } from '../providers/UserContext';
import LoadingMask from './shared/LoadingMask';
import { useAuth } from '../providers/AuthContext';

const ProtectedRoute = ({ admin, children }) => {
  const user = useUser();
  const auth = useAuth();

  if (auth.user === undefined) {
    return <LoadingMask />;
  }

  if (auth.user === null) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Container sx={{ padding: '15px' }}>
          <Grid
            container
            spacing={2}
            justifyContent='center'
            alignItems='center'
          >
            <Grid
              xs={12}
              md={12}
              lg={12}
              justifyContent='center'
              display='flex'
            >
              <Typography variant='h5'>
                You must login to access this page.
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={12}
              lg={12}
              display='flex'
              justifyContent='center'
            >
              <Button
                onClick={() => auth.user_manager.signinRedirect()}
                variant='contained'
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  if (auth.user && !user.entity_id) {
    return <LoadingMask />;
  }

  // if (user.entity_id && !user.terms_version) {
  //   return <Navigate to='/account/new' />;
  // }

  if (admin && !user.admin) {
    return (
      <Container sx={{ padding: '15px' }}>
        <Grid container spacing={2} justifyContent='center' alignItems='center'>
          <Grid xs={12} md={12} lg={12} justifyContent='center' display='flex'>
            <Typography variant='h5'>
              Only administrators can access this page
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return children ? children : <Outlet />;
};
export default ProtectedRoute;
