import React from 'react';
import {
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CloseIcon from '@mui/icons-material/Close';

function DocumentCheckResultDialog({ doc_check, result, open, handleClose }) {
  return (
    <Dialog onClose={handleClose} open={open} maxWidth='md'>
      <DialogTitle>
        <Grid container direction='row' alignItems='center'>
          <Grid xs>
            <b>{doc_check.name}</b>
          </Grid>
          <Grid xs={1} sx={{ maxWidth: '25px' }}>
            <IconButton aria-label='close' onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box
          padding='10px'
          sx={{
            maxWidth: 800,
          }}
        >
          <Grid container spacing={2} sx={{ width: '100%' }}>
            <Grid xs={12} md={12} lg={12}>
              <Typography variant='body2' sx={{ whiteSpace: 'pre-wrap' }}>
                {result}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
export default DocumentCheckResultDialog;
