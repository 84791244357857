import React, { useEffect, useState } from 'react';
import {
  Stack,
  Typography,
  Alert,
  AlertTitle,
  Toolbar,
  Divider,
  Button,
  IconButton,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { createChat, updateChat } from '@/actions/chat';
import { useUser } from '@/providers/UserContext';

import LoadingMask from '@/components/shared/LoadingMask';

import SearchDocuments from './SearchDocuments';
import { useConfiguration } from '@/providers/ConfigurationContext';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import { getChat } from '@/actions/chat';
import AuditNameField from './AuditNameField';
import { LoadingButton } from '@mui/lab';
import CHAT_TYPES from '../../../chatTypes';

function ExternalAuditForm() {
  const user = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const configuration = useConfiguration();
  const [chat, setChat] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pristine, setPristine] = useState(true);
  const [doc_id, setDocId] = useState(null);
  const [name, setName] = useState('');
  const [name_error, setNameError] = useState(false);

  const sp = new URLSearchParams(location.search);
  const audit_id = sp.get('audit_id');

  useEffect(() => {
    if (user?.entity_id && !chat) {
      if (audit_id) {
        getChat(configuration.api, audit_id)
          .then((data) => {
            setChat(data);
            setName(data?.name);
            setDocId(data?.doc_id);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [user?.entity_id, audit_id, chat, setChat, setDocId, configuration.api]);

  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      name,
      doc_id,
      type: CHAT_TYPES.QUARTERMASTER,
    };
    let updated_chat;
    if (audit_id) {
      updated_chat = await updateChat(
        configuration.api,
        chat.entity_id,
        payload
      );
    } else {
      updated_chat = await createChat(configuration.api, payload);
    }
    navigate(`/quartermaster/externalAudit/${updated_chat.entity_id}`);
  };

  if (!user?.entity_id || (!chat && audit_id)) {
    return <LoadingMask />;
  }

  return (
    <>
      <Toolbar
        sx={{
          minHeight: '55px !important',
        }}
      >
        <IconButton sx={{ mr: 2 }} onClick={() => navigate(-1)}>
          <ArrowBackOutlinedIcon />
        </IconButton>
        <Typography variant='h6' sx={{ mr: 4 }}>
          {chat ? 'Edit Audit' : 'New Audit'}
        </Typography>
      </Toolbar>
      <Divider />

      <Grid container spacing={2} style={{ padding: '15px' }}>
        <Grid lg={12} md={12} xs={12}>
          <AuditNameField
            value={name}
            setValue={setName}
            error={name_error}
            setError={setNameError}
            setChanged={() => setPristine(false)}
          />
        </Grid>
        {doc_id ? (
          <>
            <Grid lg={12} md={12} xs={12}>
              <Alert severity='success'>
                <AlertTitle>
                  Interrogating document <b>{doc_id}</b>
                </AlertTitle>
              </Alert>
            </Grid>
            <Grid lg={12} md={12} xs={12}>
              <Button
                onClick={() => {
                  setDocId(null);
                }}
                variant='outlined'
              >
                Reset Selection
              </Button>
            </Grid>
            <Grid lg={12} md={12} xs={12}>
              <Divider />
            </Grid>
            <Grid lg={12} md={12} xs={12}>
              <Toolbar>
                <Stack spacing={2} direction='row'>
                  <LoadingButton
                    variant='contained'
                    onClick={handleSubmit}
                    loading={loading}
                    disabled={
                      pristine ||
                      Boolean(name_error) ||
                      !Boolean(name) ||
                      !Boolean(doc_id)
                    }
                  >
                    Submit
                  </LoadingButton>

                  <Button
                    variant='outlined'
                    color='inherit'
                    onClick={() => navigate(-1)}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Toolbar>
            </Grid>
          </>
        ) : (
          <>
            <Grid lg={12} md={12} xs={12}>
              <SearchDocuments
                chat_id={chat?.entity_id}
                selectDocument={(doc_id) => {
                  setDocId(doc_id);
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default ExternalAuditForm;
