import React from 'react';
import {
  Typography,
  Stack,
  Divider,
  Box,
  MenuList,
  MenuItem,
  ListItemIcon,
} from '@mui/material';
import { CssVarsProvider, Button } from '@mui/material-next';
import Grid from '@mui/material/Unstable_Grid2';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { createChat } from '../../../actions/chat';
import { useConfiguration } from '../../../providers/ConfigurationContext';

function ChatSelection({ type, new_name, chats, setActiveChat, setChats }) {
  const configuration = useConfiguration();
  return (
    <CssVarsProvider>
      <Box display={'flex'} sx={{ width: '100%' }}>
        <Grid container spacing={1}>
          <Grid lg={12} md={12} xs={12}>
            <Button
              variant='filledTonal'
              onClick={async () => {
                const chat = await createChat(configuration.api, {
                  name: new_name,
                  type,
                });
                const new_chats = [...chats];
                new_chats.unshift(chat);
                setChats(new_chats);
                setActiveChat(chat);
              }}
            >
              <Stack direction='row' spacing={1}>
                <AddCircleOutlineIcon />
                <Typography variant='button' display='block'>
                  {new_name}
                </Typography>
              </Stack>
            </Button>
          </Grid>

          <Grid lg={12} md={12} xs={12}>
            <Divider />
          </Grid>
          <Grid lg={12} md={12} xs={12}>
            <MenuList>
              {chats.map((chat) => {
                return (
                  <MenuItem
                    key={chat.entity_id}
                    onClick={() => setActiveChat(chat)}
                  >
                    <ListItemIcon>
                      <ChatBubbleOutlineOutlinedIcon />
                    </ListItemIcon>
                    <Typography variant='inherit' noWrap>
                      {chat.name}
                    </Typography>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Grid>
        </Grid>
      </Box>
    </CssVarsProvider>
  );
}

export default ChatSelection;
