export const getManifestChecks = async (api, manifest_id) => {
  const resp = await api.get(`/manifest/${manifest_id}/check`);
  return resp.data;
};

export const getManifestCheck = async (api, manifest_id, manifest_check_id) => {
  const resp = await api.get(
    `/manifest/${manifest_id}/check/${manifest_check_id}`
  );
  return resp.data;
};

export const createManifestCheck = async (api, manifest_id, values) => {
  const resp = await api.post(`/manifest/${manifest_id}/check`, values);
  return resp.data;
};

export const updateManifestCheck = async (
  api,
  manifest_id,
  manifest_check_id,
  values
) => {
  const resp = await api.put(
    `/manifest/${manifest_id}/check/${manifest_check_id}`,
    values
  );
  return resp.data;
};

export const deleteManifestCheck = async (
  api,
  manifest_id,
  manifest_check_id
) => {
  const resp = await api.delete(
    `/manifest/${manifest_id}/check/${manifest_check_id}`
  );
  return resp.data;
};

export const runManifestCheck = async (
  api,
  manifest_id,
  manifest_check_id,
  doc_content,
  reference_doc_content
) => {
  const token = localStorage.getItem('accqsure_jwt');

  const init = {
    // signal: AbortSignal.timeout(10000),  // this was causing timeouts with long generations
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      doc_content,
      reference_doc_content,
    }),
  };

  const response = await fetch(
    `${api.defaults.baseURL}/manifest/${manifest_id}/check/${manifest_check_id}/run`,
    init
  );

  return response;
};
