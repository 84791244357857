import React, { useEffect } from 'react';
import { useAuth, useAuthDispatch } from '../providers/AuthContext';
import { SET_AUTH_USER } from '../providers/actionTypes';

export const RefreshSession = () => {
  const auth = useAuth();
  const dispatch = useAuthDispatch();

  useEffect(() => {
    const refresh = () => {
      if (auth.user_manager && auth.user) {
        auth.user_manager
          .signinSilent()
          .then((user) => {
            dispatch({ type: SET_AUTH_USER, payload: user });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    let interval;
    if (auth.user) {
      const duration =
        auth.user.profile?.exp - auth.user.profile?.iat || 4 * 60;
      interval = setInterval(refresh, duration * 1000);
    }
    return () => clearInterval(interval);
  }, [auth, dispatch]);

  return <></>;
};
