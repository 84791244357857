import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Button,
  DialogActions,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import ChatNameField from './ChatNameField';

function ChatEditDialog({ chat, updateChat, open, handleClose }) {
  const [name, setName] = useState(chat?.name);
  const [name_error, setNameError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    await updateChat(chat.entity_id, { name });
    setLoading(false);
    handleClose();
  };

  return (
    <Dialog onClose={() => handleClose()} open={open}>
      <DialogTitle>
        <Grid container direction='row' alignItems='center'>
          <Grid item xs>
            Edit Chat
          </Grid>
          <Grid item xs={1} sx={{ maxWidth: '25px' }}>
            <IconButton aria-label='close' onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box
          padding='10px'
          sx={{
            display: 'flex',
            maxWidth: 800,
          }}
        >
          <Grid container spacing={2} sx={{ width: '100%' }}>
            <Grid item xs={12} md={12} lg={12} sx={{ minWidth: '400px' }}>
              <ChatNameField
                value={name}
                setValue={setName}
                error={name_error}
                setError={setNameError}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            color='inherit'
            onClick={handleClose}
            sx={{ mr: 1, flex: '1 1 auto' }}
          >
            Cancel
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <LoadingButton
            loading={loading}
            variant='contained'
            color='primary'
            onClick={handleSubmit}
          >
            Update
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default ChatEditDialog;
