import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function SearchField({
  value,
  error,
  setValue,
  setError,
  onSubmit,
  ...rest
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setValue(newValue);
    if (newValue === '') {
      setError('Search cannot be blank');
    } else {
      setError(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.which === 13) {
      //<------use enter key to submit form
      event.preventDefault();
      onSubmit();
    }
  };

  return (
    <Box>
      <FormControl fullWidth>
        <TextField
          {...rest}
          label='Search'
          id='search'
          aria-describedby='search-helper-text'
          onChange={handleChange}
          error={Boolean(error)}
          onKeyDown={handleKeyDown}
          value={value}
          variant='standard'
          multiline
        />
        {error ? (
          <FormHelperText id='search-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='search-helper-text'>
            Enter a short phrase that will identify the document you wish to
            audit.
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
