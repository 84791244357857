import React, { useEffect, useState } from 'react';

import { Box, TextField, Autocomplete } from '@mui/material';
import { getManifests } from '@/actions/manifest';
import { useConfiguration } from '@/providers/ConfigurationContext';
import LoadingMask from '@/components/shared/LoadingMask';

function ManifestField({ document_type_id, value, setValue }) {
  const configuration = useConfiguration();

  const [manifests, setManifests] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    if (!manifests && document_type_id) {
      getManifests(configuration.api, document_type_id)
        .then((data) => {
          setManifests(data.filter((manifest) => !manifest.global));
        })
        .catch((error) => console.log(error));
    }
    setLoading(false);
  }, [configuration.api, manifests, document_type_id]);

  const handleChange = (event, newValue) => {
    setSelected(newValue);
    setValue(newValue);
  };

  if (loading) {
    return <LoadingMask />;
  }

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '600px',
        justifyContent: 'center',
        justifyItems: 'center',
        textAlign: 'justify',
      }}
    >
      <Autocomplete
        id='manifest-field'
        options={manifests || []}
        value={selected}
        onChange={handleChange}
        getOptionLabel={(option) => (option ? `${option.name}` : undefined)}
        disableClearable={true}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label={`Select the Document's Manifest`}
            helperText={`The Manifest is the set of rules this document must comply with.`}
          />
        )}
      />
    </Box>
  );
}

export default ManifestField;
