import React, { useEffect, useState } from 'react';

import { Box, TextField, Autocomplete } from '@mui/material';
import { getDocuments } from '@/actions/document';
import { useConfiguration } from '@/providers/ConfigurationContext';
import LoadingMask from '@/components/shared/LoadingMask';

function ManifestReferenceDocumentField({
  document_type_id,
  value,
  setValue,
  setChanged,
  edit,
}) {
  const configuration = useConfiguration();

  const [documents, setDocuments] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    if (!documents && document_type_id) {
      getDocuments(configuration.api, document_type_id)
        .then((data) => {
          setDocuments(data);
        })
        .catch((error) => console.log(error));
    }
    setLoading(false);
  }, [configuration.api, documents, document_type_id]);

  const handleChange = (event, newValue) => {
    setChanged();
    setSelected(newValue);
    setValue(newValue);
  };

  if (loading) {
    return <LoadingMask />;
  }

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '600px',
        justifyContent: 'center',
        justifyItems: 'center',
        textAlign: 'justify',
      }}
    >
      <Autocomplete
        id='reference-document-field'
        options={documents || []}
        value={selected}
        onChange={handleChange}
        disabled={edit}
        getOptionLabel={(option) => (option ? `${option.name}` : undefined)}
        disableClearable={true}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label={`Select the Governing Document for the Manifest`}
            helperText={`The Governing Document is the source of the rules the Manifest will use to determine document compliance.`}
          />
        )}
      />
    </Box>
  );
}

export default ManifestReferenceDocumentField;
