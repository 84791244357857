import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Box, Link } from '@mui/material';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Link as RouterLink } from 'react-router-dom';

import { useUser } from '@/providers/UserContext';
import LoadingMask from '@/components/shared/LoadingMask';
import { useConfiguration } from '@/providers/ConfigurationContext';
import { getManifests } from '@/actions/manifest';

function AdminManifestList({ pending_only, document_type_id, setSelected }) {
  const user = useUser();
  const configuration = useConfiguration();
  const [manifests, setManifests] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  useEffect(() => {
    if (user?.entity_id && !manifests) {
      if (pending_only) {
        setManifests([]);
      } else {
        getManifests(configuration.api, document_type_id)
          .then((data) => {
            data.sort(
              (a, b) =>
                new Date(b.updated_at).valueOf() -
                new Date(a.updated_at).valueOf()
            );
            setManifests(data);
          })
          .catch((error) => console.log(error));
      }
    }
  }, [
    user?.entity_id,
    manifests,
    setManifests,
    configuration.api,
    document_type_id,
    pending_only,
  ]);

  const handleRowSelection = useCallback(
    (value) => {
      setRowSelectionModel(value);
      setSelected(value);
    },
    [setRowSelectionModel, setSelected]
  );

  const renderName = useCallback((params) => {
    return (
      <Link component={RouterLink} to={`/admin/manifests/${params.id}`}>
        {params.row.name}
      </Link>
    );
  }, []);

  const renderStatus = useCallback(({ row }) => {
    return 'Active';
  }, []);

  const convertDate = useCallback(({ value }) => {
    if (!value) {
      return value;
    }
    return new Date(value.replace(' ', 'T') + 'Z');
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        type: 'string',
        renderCell: renderName,
      },
      {
        field: 'status',
        headerName: 'Status',
        type: 'string',
        flex: 0.5,
        renderCell: renderStatus,
      },
      {
        field: 'created_at',
        headerName: 'Started',
        type: 'dateTime',
        flex: 0.75,
        valueGetter: convertDate,
        // renderCell: renderName,
      },
      {
        field: 'updated_at',
        headerName: 'Last Updated',
        type: 'dateTime',
        flex: 0.75,
        valueGetter: convertDate,
        // renderCell: renderName,
      },
    ],
    [renderName, convertDate, renderStatus]
  );

  if (!manifests) {
    return <LoadingMask />;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          height: '100%',
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            },
          }}
        >
          <DataGrid
            checkboxSelection
            columnHeaderHeight={35}
            getRowId={(row) => row.entity_id}
            columns={columns}
            rows={manifests || []}
            onSelectionModelChange={handleRowSelection}
            onRowSelectionModelChange={handleRowSelection}
            rowSelectionModel={rowSelectionModel}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                sx: {
                  button: {
                    color: 'black',
                  },
                  svg: {
                    color: 'black',
                  },
                },
                showQuickFilter: true,
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default AdminManifestList;
