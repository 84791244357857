import React, { useEffect, useState } from 'react';
import {
  Paper,
  Toolbar,
  Stack,
  IconButton,
  Typography,
  Box,
  Divider,
  Button,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { LoadingButton } from '@mui/lab';
import { ArrowBack } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingMask from '@/components/shared/LoadingMask';
import {
  getManifest,
  updateManifest,
  createManifest,
} from '@/actions/manifest';
import { useConfiguration } from '@/providers/ConfigurationContext';
import ManifestNameField from './ManifestNameField';
import ManifestDocumentTypeField from './ManifestDocumentTypeField';
import { useUser } from '@/providers/UserContext';
import { useDocTypes } from '@/providers/DocTypesContext';
import ManifestReferenceDocumentField from './ManifestReferenceDocumentField';
import ManifestReferenceDocumentTypeField from './ManifestReferenceDocumentTypeField';

export default function AdminManifestForm() {
  const location = useLocation();
  const configuration = useConfiguration();
  const user = useUser();
  const doc_types = useDocTypes();
  const navigate = useNavigate();

  const [revision, setRevision] = useState(0);
  const [pristine, setPristine] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loading_manifest, setLoadingManifest] = useState(true);
  const [manifest, setManifest] = useState(null);
  const [name, setName] = useState(manifest?.name || '');
  const [name_error, setNameError] = useState(false);
  const [document_type, setDocumentType] = useState();
  const [reference_document_type_options, setReferenceDocumentTypeOptions] =
    useState([]);
  const [reference_document_type, setReferenceDocumentType] = useState(null);
  const [reference_document, setReferenceDocument] = useState(
    manifest?.reference_document
  );
  const sp = new URLSearchParams(location.search);
  const manifest_id = sp.get('manifest_id');

  useEffect(() => {
    if (user?.entity_id && !manifest) {
      if (manifest_id) {
        getManifest(configuration?.api, manifest_id)
          .then((data) => {
            setManifest(data);
            setName(data?.name);
            setReferenceDocument(data?.reference_document);
            setLoadingManifest(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      setLoadingManifest(false);
    }
  }, [
    configuration?.api,
    user?.entity_id,
    manifest,
    manifest_id,

    setManifest,
    setName,
    setReferenceDocument,
    setLoadingManifest,
  ]);

  useEffect(() => {
    if (doc_types && !document_type) {
      if (manifest_id) {
        if (manifest) {
          const doc_type = doc_types.find(
            (doc_type) => doc_type.entity_id === manifest.document_type_id
          );
          setDocumentType(doc_type);
        }
      } else {
        const maxLevel = Math.max(
          ...doc_types.map((doc_type) => doc_type.level)
        );
        const filteredArray = doc_types.filter(
          (doc_type) => doc_type.level === maxLevel
        );
        setDocumentType(filteredArray[0]);
      }
    }
  }, [document_type, manifest, manifest_id, doc_types, setDocumentType]);

  useEffect(() => {
    setRevision((prevState) => prevState + 1);
  }, [manifest, doc_types, document_type, reference_document_type]);

  useEffect(() => {
    if (document_type) {
      const filteredArray = doc_types.filter(
        (doc_type) => doc_type.level < document_type.level
      );
      setReferenceDocumentTypeOptions(filteredArray);
    }
  }, [document_type, doc_types, setReferenceDocumentTypeOptions]);

  useEffect(() => {
    if (reference_document && (!name || name === '')) {
      setName(reference_document.name);
    }
  }, [reference_document, name, setName]);

  const handleSubmit = async () => {
    setLoading(true);
    const payload = Object.fromEntries(
      Object.entries({
        name,
        document_type_id: document_type.entity_id,
        reference_document_id: reference_document.entity_id,
      }).filter(([k, v]) => v !== undefined && v !== '')
    );
    try {
      if (manifest) {
        await updateManifest(configuration.api, manifest_id, payload);
      } else {
        await createManifest(configuration.api, payload);
      }

      navigate(`/admin/manifests`);
    } catch (error) {
      setLoading(false);
    }
  };

  if (loading_manifest) {
    return <LoadingMask />;
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={12} lg={12}>
        <Paper sx={{ display: 'flex', width: '100%' }} elevation={2}>
          <Toolbar
            sx={{
              minHeight: '55px !important',
            }}
          >
            <Stack spacing={2} direction='row' alignItems='center'>
              <IconButton onClick={() => navigate(-1)} disabled={loading}>
                <ArrowBack />
              </IconButton>
              <Typography variant='h5'>
                {manifest ? `Edit ${manifest.name}` : `Create New Manifest`}
              </Typography>
            </Stack>
          </Toolbar>
        </Paper>
      </Grid>

      <Grid xs={12} md={12} lg={12}>
        <Box
          padding='15px'
          sx={{
            maxWidth: 600,
          }}
        >
          <Grid container spacing={2}>
            <Grid xs={12} md={12} lg={12}>
              <ManifestDocumentTypeField
                key={`document_type_${revision}`}
                value={document_type}
                setValue={setDocumentType}
                setChanged={() => setPristine(false)}
                edit={Boolean(manifest)}
              />
            </Grid>
            {document_type && !Boolean(manifest) ? (
              <Grid xs={12} md={12} lg={12}>
                <ManifestReferenceDocumentTypeField
                  key={`reference_document_type_${revision}`}
                  options={reference_document_type_options}
                  value={reference_document_type}
                  setValue={setReferenceDocumentType}
                  edit={Boolean(manifest)}
                />
              </Grid>
            ) : undefined}
            {reference_document_type || Boolean(manifest) ? (
              <Grid xs={12} md={12} lg={12}>
                <ManifestReferenceDocumentField
                  key={`reference_document_${revision}`}
                  document_type_id={reference_document_type?.entity_id}
                  value={reference_document}
                  setValue={setReferenceDocument}
                  setChanged={() => setPristine(false)}
                  edit={Boolean(manifest)}
                />
              </Grid>
            ) : undefined}

            <Grid xs={12} md={12} lg={12}>
              <ManifestNameField
                key={`name_${revision}`}
                value={name}
                error={name_error}
                setValue={setName}
                setError={setNameError}
                setChanged={() => setPristine(false)}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid xs={12} md={12} lg={12}>
        <Divider />
        <Toolbar>
          <Stack spacing={2} direction='row'>
            <LoadingButton
              variant='contained'
              onClick={handleSubmit}
              loading={loading}
              disabled={
                pristine ||
                Boolean(name_error) ||
                !Boolean(name) ||
                !Boolean(document_type)
              }
            >
              Submit
            </LoadingButton>

            <Button
              variant='outlined'
              onClick={() => navigate('/admin/manifests')}
              disabled={loading}
            >
              Cancel
            </Button>
          </Stack>
        </Toolbar>
      </Grid>
    </Grid>
  );
}
