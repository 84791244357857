import React, { useState } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { Box } from '@mui/material';

import Header from './Header';
import { ConfigurationProvider } from '../providers/ConfigurationContext';
import { UserProvider } from '../providers/UserContext';
import { AuthProvider } from '../providers/AuthContext';
import { PageTitleProvider } from '../providers/PageTitleContext';
import { DocTypesProvider } from '../providers/DocTypesContext';
import MainSidebar from './MainSidebar';

const Layout = () => {
  const [open, setOpen] = useState(false);

  const toggleMainSidebar = () => {
    setOpen(!open);
  };

  return (
    <PageTitleProvider>
      <ConfigurationProvider>
        <AuthProvider>
          <UserProvider>
            <DocTypesProvider>
              <Header
                mainSidebarOpen={open}
                toggleMainSidebar={toggleMainSidebar}
              />
              <MainSidebar
                sx={{
                  display: 'flex',
                }}
                open={open}
                toggleDrawer={toggleMainSidebar}
              />
              <Box
                component='main'
                sx={{
                  flexGrow: 1,
                  height: '100vh',
                  overflow: 'auto',
                }}
              >
                <Outlet />
                <ScrollRestoration />
              </Box>
            </DocTypesProvider>
          </UserProvider>
        </AuthProvider>
      </ConfigurationProvider>
    </PageTitleProvider>
  );
};

export default Layout;
