import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Navigate } from 'react-router-dom';

import { useUser, useUserDispatch } from '../../providers/UserContext';
import { useAuth, useAuthDispatch } from '../../providers/AuthContext';
import {
  SET_AUTH_USER,
  SET_PAGE_TITLE,
  USER_FETCH,
} from '../../providers/actionTypes';
import LoadingMask from '../shared/LoadingMask';
import { createUser, getUser } from '../../actions/user';
import { useConfiguration } from '../../providers/ConfigurationContext';
import { usePageTitleDispatch } from '../../providers/PageTitleContext';

const page_title = 'Authenticate';

const Callback = () => {
  const configuration = useConfiguration();
  const user = useUser();
  const user_dispatch = useUserDispatch();
  const auth = useAuth();
  const auth_dispatch = useAuthDispatch();
  const [loading, setLoading] = useState(false);

  const pageTitleDispatch = usePageTitleDispatch();

  useEffect(() => {
    pageTitleDispatch({ type: SET_PAGE_TITLE, payload: page_title });
  }, [pageTitleDispatch]);

  useEffect(() => {
    if (auth?.user_manager && auth?.user === undefined) {
      auth.user_manager
        .getUser(configuration.api)
        .then((user) => {
          if (user) {
            auth_dispatch({ type: SET_AUTH_USER, payload: user });
          } else {
            auth.user_manager
              .signinRedirectCallback()
              .then((user) => {
                auth_dispatch({ type: SET_AUTH_USER, payload: user });
              })
              .catch((error) => console.log(error));
          }
        })
        .catch((error) => console.log(error));
    }
  }, [auth?.user_manager, auth?.user, auth_dispatch, configuration.api]);

  useEffect(() => {
    if (auth.user && !user?.entity_id && !loading) {
      setLoading(true);
      getUser(configuration.api)
        .then((result) => {
          user_dispatch({ type: USER_FETCH, payload: result });
        })
        .catch((error) => {
          console.log(error);
          createUser(configuration.api)
            .then((result) => {
              user_dispatch({ type: USER_FETCH, payload: result });
            })
            .catch((error) => console.log(error));
          // create user should happen from Callback page
        });
    }
  }, [auth.user, user, user_dispatch, loading, setLoading, configuration.api]);

  return (
    <Box sx={{ display: 'flex' }}>
      <LoadingMask />
      {user && user?.entity_id && <Navigate to='/' />}
    </Box>
  );
};

export default Callback;
