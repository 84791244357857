import React, { useState, useCallback } from 'react';
import {
  // Button, Typography,
  Box,
  IconButton,
} from '@mui/material';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltOutlinedIcon from '@mui/icons-material/ThumbUpOffAltOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';
import ChatFeedbackDialog from './ChatFeedbackDialog';

function ChatResponseToolbar({ line, updateChatLine }) {
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const handleThumbsUp = useCallback(async () => {
    await updateChatLine(line.entity_id, { rating: 1 });
  }, [line.entity_id, updateChatLine]);

  const handleThumbsDown = useCallback(async () => {
    updateChatLine(line.entity_id, { rating: 0 })
      .then()
      .catch((error) => console.log(error));
    setFeedbackOpen(true);
  }, [line.entity_id, updateChatLine]);

  return (
    <Box sx={{ alignContent: 'center' }}>
      {/* <Button variant='secondary'>
        <Typography variant='caption'>View Sources</Typography>
      </Button> */}
      <IconButton
        disabled={line.rating === 1}
        onClick={line.rating !== 1 ? handleThumbsUp : undefined}
      >
        {line.rating === 1 ? (
          <ThumbUpIcon fontSize='small' color='primary' />
        ) : (
          <ThumbUpOffAltOutlinedIcon fontSize='small' />
        )}
      </IconButton>
      <IconButton
        disabled={line.rating === 0}
        onClick={line.rating !== 0 ? handleThumbsDown : undefined}
      >
        {line.rating === 0 ? (
          <ThumbDownIcon fontSize='small' color='primary' />
        ) : (
          <ThumbDownOffAltOutlinedIcon fontSize='small' />
        )}
      </IconButton>
      {feedbackOpen ? (
        <ChatFeedbackDialog
          open={feedbackOpen}
          handleClose={() => setFeedbackOpen(false)}
          line={line}
          updateChatLine={updateChatLine}
        />
      ) : undefined}
    </Box>
  );
}

export default ChatResponseToolbar;
