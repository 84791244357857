const cleanEmptyResponseMessages = (chat_history) => {
  const messages = [];

  for (let i = 0; i < chat_history.length; i++) {
    const message = chat_history[i];
    if (message.role === 'user') {
      if (chat_history[i + 1]?.role === 'assistant') {
        messages.push(message);
      }
    } else {
      messages.push(message);
    }
  }
  return messages;
};
export default cleanEmptyResponseMessages;
