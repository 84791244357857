import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Typography,
  Toolbar,
  Divider,
  Button,
  IconButton,
  Tab,
  Tabs,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { useUser } from '@/providers/UserContext';

import LoadingMask from '@/components/shared/LoadingMask';

import { useConfiguration } from '@/providers/ConfigurationContext';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import { getInspection, deleteInspection } from '@/actions/inspection';
import { getManifest } from '@/actions/manifest';
import { getManifestChecks } from '@/actions/manifestCheck';
import INSPECTION_TYPES from '@/components/inspectionTypes';
import InspectionDeleteDialog from '../../InspectionDeleteDialog';
import toNiceTimestamp from '@/util/toNiceTimestamp';
import InspectionDocument from './InspectionDocument';
import INSPECTION_STATUSES from '../../../inspectionStatuses';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function Inspection() {
  const user = useUser();
  const { inspection_id } = useParams();
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const configuration = useConfiguration();
  const [inspection, setInspection] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [manifests, setManifests] = useState(null);

  useEffect(() => {
    if (user?.entity_id && !inspection) {
      getInspection(configuration.api, inspection_id)
        .then((data) => {
          setInspection(data);
        })
        .catch((error) => {
          console.log(error);
          navigate('/quartermaster');
        });
    }
  }, [
    user?.entity_id,
    inspection_id,
    inspection,
    setInspection,
    configuration.api,
    navigate,
  ]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (inspection?.status === INSPECTION_STATUSES.DRAFT && !manifests) {
      const task = Promise.all(
        inspection.manifests.map(async (inspection_manifest) => {
          const [manifest, checks] = await Promise.all([
            getManifest(configuration.api, inspection_manifest.manifest_id),
            getManifestChecks(
              configuration.api,
              inspection_manifest.manifest_id
            ),
          ]);
          return { ...manifest, checks };
        })
      );
      task
        .then((data) => {
          setManifests(data);
        })
        .catch((error) => {
          console.log(error);
          navigate('/quartermaster');
        });
    }
  }, [inspection, manifests, setManifests, configuration.api, navigate]);

  if (
    !inspection ||
    (inspection?.status === INSPECTION_STATUSES.DRAFT && !manifests)
  ) {
    return <LoadingMask />;
  }
  console.log(manifests);
  const base_path =
    inspection?.type === INSPECTION_TYPES.EFFECTIVE
      ? '/quartermaster/internalAudit'
      : '/quartermaster/evaluator';
  const handleDeleteInspection = async () => {
    await deleteInspection(configuration.api, inspection_id);
    navigate(base_path);
  };

  return (
    <>
      <Toolbar
        sx={{
          minHeight: '55px !important',
        }}
      >
        <IconButton sx={{ mr: 2 }} onClick={() => navigate(-1)}>
          <ArrowBackOutlinedIcon />
        </IconButton>
        <Typography variant='h6' sx={{ mr: 4 }}>
          {inspection.name}
        </Typography>
        <Stack direction={'row'} spacing={2}>
          <Button
            onClick={() =>
              navigate({
                pathname: `${base_path}/edit`,
                search: `?inspection_id=${inspection_id}`,
              })
            }
            startIcon={<EditOutlinedIcon />}
          >
            Edit
          </Button>
          <Button
            onClick={() => setDeleteOpen(true)}
            startIcon={<DeleteOutlinedIcon />}
          >
            Delete
          </Button>
        </Stack>
      </Toolbar>
      <Divider />

      <Box padding='15px'>
        <Typography variant='subtitle'>Basic Information</Typography>
        <Divider />
        <br />
        <Grid
          container
          component='dl' // mount a Definition List
          spacing={0}
          sx={{ display: 'table' }}
        >
          <Grid>
            <Typography
              component='dt'
              variant='body1'
              sx={{ display: 'table-cell', width: '250px' }}
            >
              <b>ID:</b>
            </Typography>
            <Typography
              component='dd'
              variant='body2'
              sx={{ display: 'table-cell' }}
            >
              {inspection?.entity_id}
            </Typography>
          </Grid>
          <Grid>
            <Typography
              component='dt'
              variant='body1'
              sx={{ display: 'table-cell', width: '250px' }}
            >
              <b>Status:</b>
            </Typography>
            <Typography
              component='dd'
              variant='body2'
              sx={{ display: 'table-cell' }}
            >
              {inspection?.status}
            </Typography>
          </Grid>
          <Grid>
            <Typography
              component='dt'
              variant='body1'
              sx={{ display: 'table-cell', width: '250px' }}
            >
              <b>Created:</b>
            </Typography>
            <Typography
              component='dd'
              variant='body2'
              sx={{ display: 'table-cell' }}
            >
              {toNiceTimestamp(inspection?.created_at, true)}
            </Typography>
          </Grid>
          <Grid>
            <Typography
              component='dt'
              variant='body1'
              sx={{ display: 'table-cell', width: '250px' }}
            >
              <b>Last Updated:</b>
            </Typography>
            <Typography
              component='dd'
              variant='body2'
              sx={{ display: 'table-cell' }}
            >
              {toNiceTimestamp(inspection?.updated_at, true)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box padding='15px'>
        <Typography variant='subtitle'>Documents</Typography>
        <Divider />
        <br />
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: 'background.paper',
            display: 'flex',
          }}
        >
          <Tabs
            orientation='vertical'
            variant='scrollable'
            value={tab}
            onChange={handleTabChange}
            sx={{
              borderRight: 1,
              borderColor: 'divider',
              maxWidth: '200px',
              minWidth: '150px',
            }}
          >
            {(inspection?.documents || []).map((document, i) => {
              return (
                <Tab
                  key={document.document_doc_id}
                  label={document.document_name}
                  value={i}
                  // sx={{ maxWidth: '200px', minWidth: '100px' }}
                />
              );
            })}
          </Tabs>
          {(inspection?.documents || []).map((document, i) => {
            console.log(document);
            return (
              <TabPanel key={`${i}`} value={tab} index={i}>
                <InspectionDocument
                  manifests={manifests}
                  inspection={inspection}
                  document={document}
                />
              </TabPanel>
            );
          })}
        </Box>
      </Box>
      <InspectionDeleteDialog
        key={`delete_${inspection?.updated_at}`}
        inspection={inspection}
        open={deleteOpen}
        deleteChat={handleDeleteInspection}
        handleClose={() => setDeleteOpen(false)}
      />
    </>
  );
}

export default Inspection;
