import React, { useState } from 'react';
import {
  CircularProgress,
  Typography,
  Stack,
  TableRow,
  TableCell,
  Link,
} from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DocumentCheckResultDialog from './DocumentCheckResultDialog';
import WarningIcon from '@mui/icons-material/Warning';
import WorkInstructionViewDialog from './WorkInstructionViewDialog';
import CHECK_RESULT_STATUSES from './checkResultStatuses';

function DocumentCheck({ global, manifest, doc_check, reference_doc_content }) {
  const [open, setOpen] = useState(false);
  const [openWorkInstruction, setOpenWorkInstruction] = useState(false);

  const renderStatus = (status) => {
    switch (status) {
      case CHECK_RESULT_STATUSES.PASSED:
        return (
          <Stack direction='row' spacing={1} alignItems='center'>
            <CheckCircleIcon color='success' />
            <Typography variant='body2'>{status}</Typography>
          </Stack>
        );
      case CHECK_RESULT_STATUSES.UNKNOWN:
        return (
          <Stack direction='row' spacing={1} alignItems='center'>
            <WarningIcon color='warning' />
            <Typography variant='body2'>{status}</Typography>
          </Stack>
        );
      case CHECK_RESULT_STATUSES.FAILED:
        return (
          <Stack direction='row' spacing={1} alignItems='center'>
            <HighlightOffIcon color='error' />
            <Typography variant='body2'>{status}</Typography>
          </Stack>
        );
      default:
        return (
          <>
            <Stack direction='row' spacing={1} alignItems='center'>
              <CircularProgress size={20} />
              <Typography variant='body2'>{status}</Typography>
            </Stack>
          </>
        );
    }
  };

  const renderDetail = (status, result) => {
    if (result) {
      return result.replace(/^(PASSED|FAILED)\n/, '');
    }
  };

  return (
    <TableRow>
      {!global ? (
        <Typography variant='body'>{doc_check.section}</Typography>
      ) : undefined}
      <TableCell>
        {doc_check.global ? (
          <Typography variant='body'>{doc_check.name}</Typography>
        ) : (
          <Link
            sx={{ textAlign: 'left' }}
            component='button'
            variant='body2'
            onClick={() => {
              setOpenWorkInstruction(true);
            }}
          >
            <Typography variant='body'>{doc_check.name}</Typography>
          </Link>
        )}
      </TableCell>
      <TableCell>
        <Typography variant='body'>{renderStatus(doc_check.status)}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body' sx={{ whiteSpace: 'pre-wrap' }}>
          {renderDetail(doc_check.status, doc_check.result)}
        </Typography>
      </TableCell>
      <DocumentCheckResultDialog
        doc_check={doc_check}
        result={doc_check.result}
        open={open}
        handleClose={() => setOpen(false)}
      />
      <WorkInstructionViewDialog
        open={openWorkInstruction}
        handleClose={() => setOpenWorkInstruction(false)}
        content={reference_doc_content}
        title={`${manifest.name} Requirements`}
      />
    </TableRow>
  );
}
export default DocumentCheck;
