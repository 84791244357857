import { createContext, useContext, useReducer } from 'react';
import { SET_DOC_TYPES } from './actionTypes';

const DocTypesContext = createContext(null);

const DocTypesDispatchContext = createContext(null);

const initialState = null;

export function DocTypesProvider({ children }) {
  const [doc_types, dispatch] = useReducer(docTypesReducer, initialState);

  return (
    <DocTypesContext.Provider value={doc_types}>
      <DocTypesDispatchContext.Provider value={dispatch}>
        {children}
      </DocTypesDispatchContext.Provider>
    </DocTypesContext.Provider>
  );
}

export function useDocTypes() {
  return useContext(DocTypesContext);
}

export function useDocTypesDispatch() {
  return useContext(DocTypesDispatchContext);
}

function docTypesReducer(_doc_types, action) {
  switch (action.type) {
    case SET_DOC_TYPES: {
      return [...action.payload].sort((a, b) => {
        if (a.level === b.level) {
          return a.name - b.name;
        } else {
          return a.level - b.level;
        }
      });
    }
    default: {
      throw Error('Unknown action: ' + action);
    }
  }
}
