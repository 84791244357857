export const getDocumentTypes = async (api, limit = 50, offset = 0) => {
  const resp = await api.get('/document/type', { params: { limit, offset } });
  return resp.data;
};

export const getDocumentType = async (api, document_type_id) => {
  const resp = await api.get(`/document/type/${document_type_id}`);
  return resp.data;
};

export const createDocumentType = async (api, values) => {
  const resp = await api.post(`/document/type/`, values);
  return resp.data;
};

export const updateDocumentType = async (api, document_type_id, values) => {
  const resp = await api.put(`/document/type/${document_type_id}`, values);
  return resp.data;
};

export const deleteDocumentType = async (api, document_type_id) => {
  const resp = await api.delete(`/document/type/${document_type_id}`);
  return resp.data;
};
