import React from 'react';

import {
  FormControl,
  Box,
  FormHelperText,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

export default function PromptField({
  value,
  error,
  setValue,
  setError,
  onSubmit,
  ...rest
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setValue(newValue);
    if (newValue === '') {
      setError('Prompt cannot be blank');
    } else {
      setError(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.which === 13) {
      //<------use enter key to submit form
      event.preventDefault();
      onSubmit();
    }
  };

  return (
    <Box>
      <FormControl fullWidth>
        <TextField
          {...rest}
          label='Prompt'
          id='prompt'
          aria-describedby='prompt-helper-text'
          onChange={handleChange}
          error={Boolean(error)}
          onKeyDown={handleKeyDown}
          value={value}
          variant='outlined'
          multiline
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='send prompt'
                  onClick={onSubmit}
                  onMouseDown={onSubmit}
                  edge='end'
                >
                  <SendIcon color='primary' />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {error ? (
          <FormHelperText id='prompt-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='prompt-helper-text'>
            Enter a question to ask your documents.
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
