import React from 'react';

import {
  Drawer,
  Toolbar,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';

const drawerWidth = 240;

function AppSidebar({ headerTitle, HeaderIcon, children }) {
  return (
    <Drawer
      variant='permanent'
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: drawerWidth,
        },
        width: drawerWidth,
        zIndex: (theme) => theme.zIndex.appBar - 2, // Ensure Drawer is behind AppBar and Main Side drawer
      }}
    >
      <Toolbar />
      <List component='nav'>
        <ListItem sx={{ paddingY: 0 }}>
          <ListItemIcon sx={{ fontSize: '2rem' }}>
            <HeaderIcon fontSize='inherit' />
          </ListItemIcon>
          <ListItemText
            primary={headerTitle}
            sx={{
              '& .MuiListItemText-primary': {
                fontSize: '1.3rem', // Larger text size
              },
            }}
          />
        </ListItem>
      </List>
      <Divider />

      {children}
    </Drawer>
  );
}

export default AppSidebar;
