import { countTokens } from '../../../actions/tokenize';

const buildMessage = async (api, model_config, prompt, docs) => {
  const [prompt_tokens, doc_tokens] = await Promise.all([
    countTokens(api, prompt),
    Promise.all([
      ...docs.map(async (doc) => {
        const tokens = await countTokens(api, `${doc.content}\n\n`);
        return { content: doc.content, tokens: tokens };
      }),
    ]),
  ]);

  let token_count = model_config.prompt_template_tokens + prompt_tokens;
  let content = `>>SUMMARY<<\n`;

  for (let i = 0; i < doc_tokens.length; i++) {
    const doc = doc_tokens[i];
    if (token_count + doc.tokens < model_config.max_input_length) {
      if (i === 0) {
        content += `${doc.content}`;
      } else {
        content += `\n\n${doc.content}`;
      }
      token_count += doc.tokens;
    }
  }
  content += `\n\n>>QUESTION<<\n${prompt}`;

  return {
    name: 'User',
    role: 'user',
    content,
    content_tokens: token_count,
  };
};
export default buildMessage;
