class Semaphore {
  constructor(maxConcurrent) {
    this.maxConcurrent = maxConcurrent;
    this.currentConcurrent = 0;
    this.queue = [];
  }

  async acquire() {
    if (this.currentConcurrent < this.maxConcurrent) {
      this.currentConcurrent++;
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      this.queue.push(resolve);
    });
  }

  release() {
    if (this.queue.length > 0) {
      const nextTask = this.queue.shift();
      nextTask();
    } else {
      this.currentConcurrent--;
    }
  }

  async run(task) {
    await this.acquire();
    try {
      await task();
    } finally {
      this.release();
    }
  }
}
export default Semaphore;
