import React, { useState } from 'react';

import {
  Drawer,
  Toolbar,
  Typography,
  Divider,
  Box,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Unstable_Grid2';
import ManifestCheckNameField from './ManifestCheckNameField';
import ManifestCheckSectionField from './ManifestCheckSectionField';
import ManifestCheckPromptField from './ManifestCheckPromptField';

import {
  updateManifestCheck,
  createManifestCheck,
} from '@/actions/manifestCheck';
import { useConfiguration } from '@/providers/ConfigurationContext';

const drawerWidth = 400;

function AdminManifestCheckDrawer({
  open,
  toggleDrawer,
  manifest_id,
  manifest_check,
  setManifestCheck,
}) {
  const configuration = useConfiguration();
  const [pristine, setPristine] = useState(true);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(manifest_check?.name || undefined);
  const [name_error, setNameError] = useState(false);
  const [section, setSection] = useState(manifest_check?.section || undefined);
  const [section_error, setSectionError] = useState(false);
  const [prompt, setPrompt] = useState(manifest_check?.prompt || undefined);
  const [prompt_error, setPromptError] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const payload = Object.fromEntries(
      Object.entries({
        name,
        section,
        prompt,
      }).filter(([k, v]) => v !== undefined && v !== '')
    );
    try {
      if (manifest_check) {
        const resp = await updateManifestCheck(
          configuration.api,
          manifest_id,
          manifest_check?.entity_id,
          payload
        );
        setManifestCheck(resp);
      } else {
        const resp = await createManifestCheck(
          configuration.api,
          manifest_id,
          payload
        );
        setManifestCheck(resp);
      }
    } catch (error) {
      setLoading(false);
    }
    toggleDrawer();
  };

  return (
    <>
      <Drawer
        anchor='right'
        open={open}
        onClose={toggleDrawer}
        sx={{
          height: '100%',
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
          zIndex: (theme) => theme.zIndex.appBar - 1, // Ensure Drawer is behind AppBar
        }}
      >
        <Toolbar />
        <Toolbar
          sx={{
            minHeight: '55px !important',
          }}
        >
          <Typography variant='h6' sx={{ mr: 4 }}>
            {manifest_check ? `Edit Check` : 'New Check'}
          </Typography>
        </Toolbar>
        <Divider />
        <Box padding='15px'>
          <Grid container spacing={2}>
            <Grid xs={12} md={12} lg={12}>
              <ManifestCheckNameField
                value={name}
                setValue={setName}
                error={name_error}
                setError={setNameError}
                setChanged={() => setPristine(false)}
              />
            </Grid>
            <Grid xs={12} md={12} lg={12}>
              <ManifestCheckSectionField
                value={section}
                setValue={setSection}
                error={section_error}
                setError={setSectionError}
                setChanged={() => setPristine(false)}
              />
            </Grid>
            <Grid xs={12} md={12} lg={12}>
              <ManifestCheckPromptField
                value={prompt}
                setValue={setPrompt}
                error={prompt_error}
                setError={setPromptError}
                setChanged={() => setPristine(false)}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Divider />
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <LoadingButton
            variant='contained'
            onClick={handleSubmit}
            loading={loading}
            disabled={
              pristine ||
              Boolean(name_error) ||
              Boolean(section_error) ||
              Boolean(prompt_error) ||
              !Boolean(name) ||
              !Boolean(section) ||
              !Boolean(prompt)
            }
          >
            Submit
          </LoadingButton>
          <Button variant='outlined' onClick={toggleDrawer} disabled={loading}>
            Cancel
          </Button>
        </Toolbar>
      </Drawer>
    </>
  );
}

export default AdminManifestCheckDrawer;
