import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Initializing from './Initializing';
import Layout from './Layout';
import Landing from './Landing';
import Voyager from './Voyager';
import Quartermaster from './Quartermaster';
import Login from './Auth/Login';
import Callback from './Auth/Callback';
import Logout from './Auth/Logout';
import ProtectedRoute from './ProtectedRoute';
import ExternalAudits from './Quartermaster/ExternalAudits';
import Evaluator from './Quartermaster/Evaluator';
import InternalAudit from './Quartermaster/InternalAudit';
import ExternalAudit from './Quartermaster/ExternalAudits/ExternalAudit';
import ExternalAuditForm from './Quartermaster/ExternalAudits/ExternalAuditForm';
import Admin from './Admin';
import AdminManifests from './Admin/AdminManifests';
import AdminManifestForm from './Admin/AdminManifests/AdminManifestForm';
import AdminManifest from './Admin/AdminManifests/AdminManifest';
import Inspection from './Quartermaster/Inspections/Inspection';
import InspectionForm from './Quartermaster/Inspections/InspectionForm';
import INSPECTION_TYPES from './inspectionTypes';
// import Inspections from './Quartermaster/Inspections';

const router = createBrowserRouter(
  [
    {
      element: <Layout />,
      children: [
        {
          path: '/',
          element: (
            <ProtectedRoute>
              <Landing />
            </ProtectedRoute>
          ),
          children: [],
        },
        {
          path: '/voyager',
          element: (
            <ProtectedRoute>
              <Voyager />
            </ProtectedRoute>
          ),
          children: [],
        },
        {
          path: '/quartermaster',
          element: (
            <ProtectedRoute>
              <Quartermaster />
            </ProtectedRoute>
          ),
          children: [
            {
              path: '/quartermaster/externalAudit',
              element: <ExternalAudits />,
              children: [],
            },
            {
              path: '/quartermaster/externalAudit/:audit_id',
              element: <ExternalAudit />,
              children: [],
            },
            {
              path: '/quartermaster/externalAudit/edit',
              element: <ExternalAuditForm />,
              children: [],
            },
            {
              path: '/quartermaster/internalAudit',
              // element: <Inspections type={INSPECTION_TYPES.EFFECTIVE} />,
              element: <InternalAudit />,
              children: [],
            },
            {
              path: '/quartermaster/evaluator',
              element: <Evaluator />,
              children: [],
            },
            {
              path: '/quartermaster/internalAudit/:inspection_id',
              element: <Inspection />,
              children: [],
            },
            {
              path: '/quartermaster/evaluator/:inspection_id',
              element: <Inspection />,
              children: [],
            },
            {
              path: '/quartermaster/internalAudit/edit',
              element: <InspectionForm type={INSPECTION_TYPES.EFFECTIVE} />,
              children: [],
            },
            {
              path: '/quartermaster/evaluator/edit',
              element: <InspectionForm type={INSPECTION_TYPES.PRELIMINARY} />,
              children: [],
            },
          ],
        },
        {
          path: '/admin',
          element: (
            <ProtectedRoute admin>
              <Admin />
            </ProtectedRoute>
          ),
          children: [
            {
              path: '/admin/quartermaster',
              element: <></>,
              children: [],
            },
            {
              path: '/admin/manifests',
              element: <AdminManifests />,
              children: [],
            },
            {
              path: '/admin/manifests/edit',
              element: <AdminManifestForm />,
              children: [],
            },
            {
              path: '/admin/manifests/:manifest_id',
              element: <AdminManifest />,
              children: [],
            },
          ],
        },
        {
          path: '/login',
          element: <Login />,
          children: [],
        },
        {
          path: '/logout',
          element: <Logout />,
          children: [],
        },
        {
          path: '/authenticate',
          element: <Callback />,
          children: [],
        },
      ],
    },
  ],
  {
    future: {
      v7_normalizeFormMethod: true,
      v7_startTransition: true,
    },
  }
);

function App() {
  return <RouterProvider router={router} fallbackElement={<Initializing />} />;
}

export default App;
