import React, { useState } from 'react';

import { Box, TextField, Autocomplete } from '@mui/material';

function ManifestReferenceDocumentTypeField({
  options,
  value,
  setValue,
  edit,
}) {
  const [selected, setSelected] = useState(value);

  const handleChange = (event, newValue) => {
    setSelected(newValue);
    setValue(newValue);
  };

  return (
    <Box>
      <Autocomplete
        id='governing-document-types-field'
        options={options}
        value={selected}
        onChange={handleChange}
        getOptionLabel={(option) => `${option.name}`}
        disableClearable={true}
        disabled={edit}
        renderInput={(params) => (
          <TextField {...params} required label='Governing Document Type' />
        )}
      />
    </Box>
  );
}
export default ManifestReferenceDocumentTypeField;
