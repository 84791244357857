import React from 'react';

import { Drawer, Toolbar } from '@mui/material';

import SidebarContent from './SidebarContent';

const drawerWidth = 280;

function MainSidebar({ open, toggleDrawer }) {
  return (
    <>
      <Drawer
        variant='temporary'
        open={open}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
          zIndex: (theme) => theme.zIndex.appBar - 1, // Ensure Drawer is behind AppBar
        }}
      >
        <Toolbar />
        <SidebarContent toggleDrawer={toggleDrawer} />
      </Drawer>
    </>
  );
}

export default MainSidebar;
