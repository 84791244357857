import React, { useEffect } from 'react';
import { Box, Container, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Navigate } from 'react-router-dom';

import { useUser } from '../../providers/UserContext';
import { useAuth } from '../../providers/AuthContext';
import { usePageTitleDispatch } from '../../providers/PageTitleContext';
import { SET_PAGE_TITLE } from '../../providers/actionTypes';

const page_title = 'Login';

const Login = () => {
  const user = useUser();
  const auth = useAuth();

  const pageTitleDispatch = usePageTitleDispatch();

  useEffect(() => {
    pageTitleDispatch({ type: SET_PAGE_TITLE, payload: page_title });
  }, [pageTitleDispatch]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Container sx={{ padding: '15px' }}>
        <Grid container spacing={2} justifyContent='center' alignItems='center'>
          <Grid xs={12} md={12} lg={12} justifyContent='center' display='flex'>
            <Typography variant='body1'>
              Click the login button to begin.
            </Typography>
          </Grid>
          <Grid xs={12} md={12} lg={12} display='flex' justifyContent='center'>
            <Button
              onClick={() => auth.user_manager.signinRedirect()}
              variant='contained'
            >
              Login
            </Button>
          </Grid>
        </Grid>
      </Container>
      {user && user?.entity_id && <Navigate to='/' />}
    </Box>
  );
};

export default Login;
