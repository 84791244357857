import { createContext, useContext, useEffect, useReducer } from 'react';
import {
  SET_API,
  SET_AUTH_CONFIG,
  SET_AUTH_USER,
  USER_LOGOUT,
} from './actionTypes';

import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { getAuthConfig } from '../actions/org';
import { useConfigurationDispatch } from './ConfigurationContext';

const AuthContext = createContext(null);

const AuthDispatchContext = createContext(null);

const initialState = { config: null, user_manager: null, user: undefined };

export function AuthProvider({ children }) {
  const [auth, dispatch] = useReducer(authReducer, initialState);
  const configuration_dispatch = useConfigurationDispatch();

  useEffect(() => {
    if (!auth.config) {
      getAuthConfig().then((config) => {
        configuration_dispatch({
          type: SET_API,
          payload: config?.api_endpoint,
        });
        dispatch({ type: SET_AUTH_CONFIG, payload: config });
      });
    }
  }, [auth.config, configuration_dispatch, dispatch]);

  return (
    <AuthContext.Provider value={auth}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

export function useAuthDispatch() {
  return useContext(AuthDispatchContext);
}

function authReducer(auth, action) {
  switch (action.type) {
    case SET_AUTH_CONFIG: {
      const userManager = new UserManager({
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        loadUserInfo: true,
        ...action.payload,
      });

      return {
        ...auth,
        user_manager: userManager,
        config: { ...action.payload },
      };
    }
    case SET_AUTH_USER: {
      if (action.payload && action.payload?.access_token) {
        localStorage.setItem(
          'accqsure_jwt',
          action.payload && action.payload?.access_token
        );
      }
      return { ...auth, user: action.payload };
    }
    case USER_LOGOUT: {
      localStorage.removeItem('accqsure_jwt');
      return { ...auth, user: null };
    }
    default: {
      throw Error('Unknown action: ' + JSON.stringify(action));
    }
  }
}
