import React, { useEffect } from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { useUser, useUserDispatch } from '../../providers/UserContext';
import LoadingMask from '../shared/LoadingMask';
import { USER_LOGOUT } from '../../providers/actionTypes';
import { useAuth } from '../../providers/AuthContext';

const Logout = () => {
  const auth = useAuth();
  const user = useUser();
  const dispatch = useUserDispatch();

  useEffect(() => {
    dispatch({ type: USER_LOGOUT });
  }, [dispatch]);

  if (!user?.entity_id) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Container sx={{ padding: '15px' }}>
          <Grid
            container
            spacing={2}
            justifyContent='center'
            alignItems='center'
          >
            <Grid
              xs={12}
              md={12}
              lg={12}
              justifyContent='center'
              display='flex'
            >
              <Typography variant='h5'>
                You have been successfully logged out.
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={12}
              lg={12}
              justifyContent='center'
              display='flex'
            >
              <Typography variant='body1'>
                Click the login button to start over.
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={12}
              lg={12}
              display='flex'
              justifyContent='center'
            >
              <Button
                onClick={() => auth.user_manager.signinRedirect()}
                variant='contained'
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  } else {
    return <LoadingMask />;
  }
};

export default Logout;
