import buildMessage from './buildMessage';
import cleanEmptyResponseMessages from './cleanEmptyResponseMessages';

const buildMessages = async (api, model_config, prompt, docs, chat_history) => {
  const message = await buildMessage(api, model_config, prompt, docs);

  const messages = [message];

  let used_tokens = message.content_tokens;
  const clean_history = cleanEmptyResponseMessages(chat_history);
  for (
    let pair_index = clean_history.length / 2 - 1;
    pair_index >= 0;
    pair_index--
  ) {
    const question = clean_history[pair_index * 2];
    const answer = clean_history[pair_index * 2 + 1];

    const chat_pair_length =
      model_config.chat_pair_tokens +
      question.content_tokens +
      answer.content_tokens;
    if (chat_pair_length + used_tokens > model_config.max_input_length) {
      break;
    } else {
      messages.unshift(answer);
      messages.unshift(question);
      used_tokens += chat_pair_length;
    }
  }

  return messages;
};
export default buildMessages;
