import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Button,
  DialogActions,
  Alert,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';

function ManifestDeleteDialog({
  manifest_id,
  deleteManifest,
  open,
  handleClose,
}) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    await deleteManifest(manifest_id);
    setLoading(false);
    handleClose();
  };

  return (
    <Dialog onClose={() => handleClose()} open={open}>
      <DialogTitle>
        <Grid container direction='row' alignItems='center'>
          <Grid xs>Delete Manifest</Grid>
          <Grid xs={1} sx={{ maxWidth: '25px' }}>
            <IconButton aria-label='close' onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box
          padding='10px'
          sx={{
            display: 'flex',
            maxWidth: 800,
          }}
        >
          <Alert severity='error'>
            Deleting a manifest will permanently delete the manifest and all
            configured checks. This operation is not reversible. Are you sure
            you want to delete?
          </Alert>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            color='inherit'
            onClick={handleClose}
            sx={{ mr: 1, flex: '1 1 auto' }}
          >
            Cancel
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <LoadingButton
            loading={loading}
            variant='contained'
            color='primary'
            onClick={handleSubmit}
          >
            Delete
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default ManifestDeleteDialog;
