import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  CardMedia,
  CardHeader,
} from '@mui/material';

function BotCard({ title, image, text, link, comingSoon }) {
  const navigate = useNavigate();
  return (
    <Card sx={{ width: '100%', maxWidth: 400 }} raised>
      <CardActionArea disabled={comingSoon} onClick={() => navigate(link)}>
        <Box sx={{ position: 'relative' }}>
          <CardHeader title={title} />
          <CardMedia
            component='img'
            sx={{ maxHeight: '200px', objectFit: 'contain' }}
            image={image}
          />
          {comingSoon ? (
            <Box
              sx={{
                position: 'absolute',
                top: 50,
                left: 0,
                width: '100%',
                bgcolor: 'rgba(0, 0, 0, 0.54)',
                color: 'white',
                padding: '10px',
              }}
            >
              <Typography variant='h5'>Coming Soon</Typography>
            </Box>
          ) : undefined}
        </Box>

        <CardContent>
          <Typography
            sx={{
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap',
            }}
            variant='body2'
          >
            {text}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default BotCard;
