import React, { useState } from 'react';

import {
  Box,
  Collapse,
  useMediaQuery,
  useTheme,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AppSidebar from './AppSidebar';

const AppSidebarLayout = ({
  headerTitle,
  HeaderIcon,
  SidebarContent,
  children,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  if (isMobile) {
    return (
      <>
        <List
          component='nav'
          sx={{
            width: '100%',
            zIndex: (theme) => theme.zIndex.appBar - 2,
          }}
        >
          <ListItemButton sx={{ paddingY: 0 }} onClick={handleClick}>
            <ListItemIcon sx={{ fontSize: '2rem' }}>
              <HeaderIcon fontSize='inherit' />
            </ListItemIcon>
            <ListItemText
              primary={headerTitle}
              sx={{
                '& .MuiListItemText-primary': {
                  fontSize: '1.3rem', // Larger text size
                },
              }}
            />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout='auto' unmountOnExit>
            {SidebarContent}
          </Collapse>
        </List>
        {children}
      </>
    );
  } else {
    return (
      <Box sx={{ display: 'flex' }}>
        <AppSidebar
          sx={{
            display: 'flex',
          }}
          headerTitle={headerTitle}
          HeaderIcon={HeaderIcon}
        >
          {SidebarContent}
        </AppSidebar>
        <Box
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          {children}
        </Box>
      </Box>
    );
  }
};

export default AppSidebarLayout;
